<template>
  <p class="h2">
    Users
    <router-link :to="{ name: 'User.Edit' }">
      <i class="bi bi-file-earmark-plus" />
    </router-link>
  </p>

  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">
          Name
        </th>
        <th scope="col" />
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in list" :key="item._id">
        <td>
          {{ item.name }}
        </td>
        <td>
          <router-link :to="{ name: 'User.Edit', params: { id: item._id } }">
            <i class="bi bi-file-earmark-text" />
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Playlists",
  components: {
  },
  props: {},
  data() {
    return {
      list: []
    };
  },

  mounted() {
    this.load();
  },
  methods: {
    load: async function () {
      try {
        const { data: list } = await axios.get(
          "/api/rest/dao/user"
        );
        this.list = list;
      } catch (err) {
        this.errors = err.response.data;
      }
    },
  },
};
</script>
